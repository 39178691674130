import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  @media (max-width: 780px) {
    margin: 40px 0 20px 0;
  }
`

export const Header = styled.h1`
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 48px;
  color: black;
  span {
    color: ${props => props.color2}
  }
  margin-bottom: 0;
  @media (max-width: 780px) {
    font-size: 38px;
  }
`

export const TextContainer = styled.div`
  display: flex;
`
