/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import NavBar from '../../components/NavBar'
import HeaderTextBlack from '../../components/HeaderTextBlack'
import HeaderTextWhite from '../../components/HeaderTextWhite'
import {
  Main, ImageContainer, TextBox, ProjectsMain, AboutBackground,
  ContactBackground, ContactTextBox, ProjectRow,
} from './styles'
import profile from '../../assets/profilepic.png'
import Card from '../../components/Card'
import owllogo from '../../assets/owllogo.png'
import personicon from '../../assets/personicon.png'
import aboutImage from '../../assets/fall.jpg'
import robot from '../../assets/cute_robot.ico'
import purposeessay from '../../assets/purposeessay.pdf'
import resume from '../../assets/CharlesOnesti_Resume.pdf'
import bsplogo from '../../assets/bsplogo.png'
import cslogo from '../../assets/cslogo.svg'
import doc from '../../assets/doc.svg'

const Home = () => (
  <>
    <AboutBackground id="about" style={{ backgroundImage: `url(${aboutImage})` }}>
      <NavBar />

      <Main>
        <ImageContainer className="animate__animated animate__fadeInLeft">
          <img src={profile} alt="" />
        </ImageContainer>
        <TextBox className="animate__animated animate__fadeInRight">
          Hi, I'm Charles. I am a Sophomore studying Computer Science at Harvard.  This
          website contains a portfolio of the completed and ongoing projects I have
          been working on in school or in my free time.
        </TextBox>
      </Main>
      <div id="projects" style={{ marginTop: '30px' }} />
    </AboutBackground>

    <HeaderTextBlack text="Projects" color1="blue" />
    <ProjectsMain>
      <ProjectRow>
        <Card
          title="Bot Begone"
          text="An online twitterbot hunting game where users can report suspicious twitter
        accounts and receive points for finding spam bots.  The application uses a webdriver
        to find twitter account information. I specialized in creating the machine learning algorithm
        that classifies twitter accounts as humans or bots."
          img={robot}
          link="https://github.com/CharlesOnesti/Bot-Begone"
        />
        <Card
          title="Bronx Shelter Project"
          text="I founded the Bronx Shelter Project to provide local shelters in the Bronx
        with essential supplies to care for the homeless.  We collect toiletries, clothes,
        and food to deliver to 5 affiliated shelters.  To date we have donated over 7000 items!"
          img={bsplogo}
          link="http://bronxshelterproject.org"
        />
      </ProjectRow>
      <ProjectRow>
        <Card
          title="Carriersource"
          text="A networking platform for the logistics industry.  I built portions of the backend which interfaces with a database of over 15 million entries."
          img={cslogo}
          link="https://staging.carriersource.io/about"
        />
        <Card
          title="Study Labs"
          text="A mobile app for creating study groups on college campuses. Integrates Google Maps API for proximity alerts."
          img={owllogo}
          link="https://github.com/CharlesOnesti/studylabs"
        />
      </ProjectRow>
      <ProjectRow>
        <Card
          title="One of my essays on philosophy"
          text="An essay about the human self and how philosophers have defined human purpose throughout history."
          img={personicon}
          link={purposeessay}
        />
      </ProjectRow>
    </ProjectsMain>

    <ContactBackground id="contact">
      <HeaderTextWhite text="Contact Me" color1="white" />
      <Main>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <ContactTextBox>
            Email: charlesonesti@college.harvard.edu
            <hr />
            Phone: 914-282-8189
            <hr />
            LinkedIn:
            {' '}
            <a href="https://www.linkedin.com/in/charles-onesti-2a8b07166/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            <hr />
            GitHub:
            {' '}
            <a href="https://github.com/CharlesOnesti" target="_blank" rel="noopener noreferrer">GitHub</a>
          </ContactTextBox>
          <ContactTextBox>
            <p style={{ textAlign: 'center' }}>My CV:</p>
            <a href={resume} target="_blank" rel="noopener noreferrer">
              <img
                src={doc}
                alt=""
                style={{
                  maxWidth: '200px',
                  borderStyle: 'solid',
                  borderColor: 'white',
                  borderWidth: '2px',
                }}
              />
            </a>
          </ContactTextBox>
        </div>
      </Main>
    </ContactBackground>
  </>
)


export default Home
