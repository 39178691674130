import styled from 'styled-components'

export const Main = styled.div`
  width: 70%;
  height: 90%;
  display: flex;
  margin: 0vh 15vw 0 15vw;
  position: relative;
  justify-content: center;
  align-items: center;
  @media (max-width: 780px) {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 80%;
  }
`

export const ImageContainer = styled.div`
  width: 200px;
  height: 320px;
  margin: 30px;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    margin: 30px 0 0 0;
    display: none;
  }
`

export const TextBox = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5px;
  position: relative;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: white;
  text-align: center;
  @media (max-width: 780px) {
    font-size: 1rem;
  }
`

export const ProjectsMain = styled.div`
  width: 85vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-around;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 40px;
  position: relative;
  min-height: 1000px;
  @media (max-width: 780px) {
    margin: 0 auto ;
  }
`
export const ProjectRow = styled.div`
  width: 85vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-around;
  position: relative;
  @media (max-width: 780px) {
    margin: 0 auto ;
  }
`

export const AboutBackground = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
`

export const ContactBackground = styled.div`
  width: 100%;
  height: 94%;
  background-color: black;
`
export const ContactTextBox = styled.div`
  min-width: 200px;
  margin: 5px;
  position: relative;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: white;
  padding: 20px;
  @media (max-width: 780px) {
    font-size: .9rem;
  }
`
