import styled from 'styled-components'

export const ButtonStyle = styled.a`
  width: ${props => (props.width || '316px')};
  height: ${props => (props.height || '55px')};
  border-radius: ${props => (props.noBorderRadius ? '0px' : '4px')};
  font-size: '17px';
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width:750px) {
    font-size: '12px';
    width: 20vw;
  }
`
