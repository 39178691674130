import styled from 'styled-components'

export const Container = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 15px;
  min-width: 200px;
  max-width: 500px;
  flex: 80%;
  @media (max-width: 700px) {
  }
`

export const TextContainer = styled.div`
  display: flex;
  margin: 0px 10px 0px 10px;
  text-align: left;
  line-height: 1.2;
  padding-bottom: 10px;
  @media (max-width: 780px) {
    text-align: center;
    margin: 15 auto;
  }
`

export const Body = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 780px) {
    display: inline-block;
  }
`

export const TitleContainer = styled.div`
  margin-top: 15px;
  display: inline-block;
  font-size: 22px;
  text-align: center;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  @media (max-width: 780px) {
    font-size: 1rem;

  }
`

export const ImageContainer = styled.img`
  display: flex;
  width: 25vw;
  min-width: 150px;
  max-width: 200px;
  min-height: 150px;
  height: auto;
  margin: auto;
`

export const Link = styled.a`
  color: black;
  text-decoration: none;
`
